import {
  Assistant,
  Knowledge,
  Mission,
  Organization,
  Thread,
  OutboundThread,
  Integration,
} from '@/typings/backend';
import { StoreInterface } from '@/stores/store';
import { Activity } from '@/typings/activity';
import { KnowledgeSuggestion } from '@/typings/KnowledgeSuggestion';
import { Tag } from '@/typings/tag';
import { User } from '@/typings/user';
import { StateCreator } from 'zustand';

export type Entity = keyof Omit<ApiSlice, 'updateData'>;

export type ApiSlice = {
  users: User[] | null;
  user: User | null; // This is the authenticated user
  organizations: Organization[] | null;
  knowledge: Knowledge[] | null;
  missions: Mission[] | null;
  assistants: Assistant[] | null;
  activities: Activity[] | null;
  knowledgeSuggestions: KnowledgeSuggestion[] | null;
  tags: Tag[] | null;
  thread: Thread[] | null;
  outboundThreads: OutboundThread[] | null;
  integrations: Integration[] | null;
  updateData: <DataType>(entity: Entity, callback: (state: DataType) => DataType) => void;
};

export const createApiSlice: StateCreator<
  StoreInterface,
  [],
  [],
  ApiSlice /* Current slice type */
> = (set, get) => ({
  users: null,
  user: null,
  organizations: null,
  knowledge: null,
  missions: null,
  assistants: null,
  activities: null,
  knowledgeSuggestions: null,
  tags: null,
  thread: null,
  outboundThreads: null,
  integrations: null,
  updateData: <DataType>(entity: Entity, callback: (state: DataType) => DataType) => {
    const store = get();
    const data = callback((store[entity] || []) as unknown as DataType);
    set({ ...store, [entity]: data as ApiSlice[typeof entity] });
  },
});
