'use client';

import { useStore } from '@/stores/store';
import axios from '@/utils/axios';
import { notifications } from '@mantine/notifications';
import useRollbar from '@/hooks/useRollbar';
import { ApiConstants, ErrorType, Keys } from './useApi';
import { AxiosError } from 'axios';

export type DeleteOptions = {
  error: {
    message: string;
    showNotification?: boolean;
    shouldSkipLogging?: (error: Error | AxiosError) => boolean;
  };
  onSuccess?: () => void;
};

function useDelete(key: Keys) {
  const updateData = useStore((store) => store.updateData);

  const rollbar = useRollbar();

  async function remove(
    id: string,
    options: DeleteOptions,
    setLoading: (loading: boolean) => void,
    setError: (error?: ErrorType) => void,
  ) {
    setLoading(true);
    setError(undefined);

    try {
      await axios.delete(`${ApiConstants[key].url}/${id}`);
      const entity = ApiConstants[key].entity;
      if (entity) {
        updateData<{ id: string }[]>(entity, (state) => state.filter((s) => s.id !== id));
      }

      setLoading(false);
      options.onSuccess?.();
    } catch (error) {
      const err = error as AxiosError<{ message: string }>;
      const errorMsg = err.response?.data?.message || err.message || 'Error';
      setLoading(false);
      if (!options.error.shouldSkipLogging?.(error as Error)) {
        rollbar.error(options.error.message, { error: JSON.stringify(err), message: errorMsg });
      }
      if (options.error.showNotification || options.error.showNotification === undefined) {
        notifications.show({
          title: 'Ocorreu um erro',
          message: options.error.message,
          color: 'red',
        });
      }

      const errorObj = { status: err?.response?.status, message: errorMsg };
      setError?.(errorObj);
    }
  }

  return { remove };
}

export default useDelete;
