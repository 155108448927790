'use client';

import React, { PropsWithChildren, useEffect } from 'react';
import RollbarProvider from '@/providers/RollbarProvider';
import StoreProvider from '@/providers/StoreProvider';
import ThemeProvider from '@/providers/ThemeProvider';
import ProgressProvider from '@/providers/ProgressProvider';
import { User } from '@/typings/user';
import NotificationBannerProvider from '@/providers/NotificationBannerProvider';
import { notifications } from '@mantine/notifications';
import { usePathname, useRouter } from 'next/navigation';
import { cleanUserLocalState } from '@/utils/user';

type Props = {
  user?: User;
  shouldLogoutUser?: boolean;
  toastMessage?: string;
};

const Providers: React.FC<PropsWithChildren<Props>> = ({
  children,
  user,
  toastMessage,
  shouldLogoutUser,
}) => {
  const pathname = usePathname();
  const router = useRouter();

  useEffect(() => {
    if (
      typeof window !== 'undefined' &&
      window.location.hostname.includes('assistente.appmasters')
    ) {
      router.push('/novo-endereco');
    }
  }, [router]);

  useEffect(() => {
    if (toastMessage && pathname?.includes('entrar')) {
      notifications.show({
        title: 'Ocorreu um erro',
        message: toastMessage,
        color: 'red',
      });
    }
  }, [toastMessage, pathname]);

  useEffect(() => {
    if (shouldLogoutUser) {
      cleanUserLocalState();
    }
  }, [shouldLogoutUser]);

  useEffect(() => {
    const preventZoom = (e: TouchEvent) => {
      // Previne o zoom se houver mais de um toque (gesto de "pinça")
      if (e.touches.length > 1) {
        e.preventDefault();
      }
    };

    const preventGesture = (e: Event) => {
      e.preventDefault();
    };

    // Bloqueia gestos de zoom no iOS
    document.addEventListener('gesturestart', preventGesture);
    document.addEventListener('gesturechange', preventGesture);
    document.addEventListener('gestureend', preventGesture);

    // Bloqueia o zoom por gesto de pinça, mas permite o scroll normal
    document.addEventListener('touchmove', preventZoom, { passive: false });

    return () => {
      document.removeEventListener('gesturestart', preventGesture);
      document.removeEventListener('gesturechange', preventGesture);
      document.removeEventListener('gestureend', preventGesture);
      document.removeEventListener('touchmove', preventZoom);
    };
  }, []);

  return (
    <RollbarProvider>
      <StoreProvider
        initialData={{ user: user || null, organization: user?.organizations?.[0]?.organization }}
      >
        <ThemeProvider>
          <ProgressProvider>
            <NotificationBannerProvider>{children}</NotificationBannerProvider>
          </ProgressProvider>
        </ThemeProvider>
      </StoreProvider>
    </RollbarProvider>
  );
};

export default Providers;
