import(/* webpackMode: "eager", webpackExports: ["ColorSchemeScript"] */ "/vercel/path0/frontend/node_modules/@mantine/core/esm/core/MantineProvider/ColorSchemeScript/ColorSchemeScript.mjs");
;
import(/* webpackMode: "eager", webpackExports: ["Notifications"] */ "/vercel/path0/frontend/node_modules/@mantine/notifications/esm/Notifications.mjs");
;
import(/* webpackMode: "eager", webpackExports: ["cleanNotifications","cleanNotificationsQueue","createNotificationsStore","hideNotification","notifications","notificationsStore","showNotification","updateNotification","updateNotificationsState","useNotifications"] */ "/vercel/path0/frontend/node_modules/@mantine/notifications/esm/notifications.store.mjs");
;
import(/* webpackMode: "eager" */ "/vercel/path0/frontend/node_modules/@mantine/notifications/styles.css");
;
import(/* webpackMode: "eager" */ "/vercel/path0/frontend/node_modules/@mantine/tiptap/styles.css");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/vercel/path0/frontend/src/components/Providers.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/vercel/path0/frontend/src/components/RegisterPWA/RegisterPWA.tsx");
;
import(/* webpackMode: "eager" */ "/vercel/path0/frontend/src/styles/global.css");
;
import(/* webpackMode: "eager" */ "/vercel/path0/frontend/src/styles/reset.css");
;
import(/* webpackMode: "eager" */ "/vercel/path0/frontend/src/styles/quill.css");
