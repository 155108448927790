import { notifications } from '@mantine/notifications';
import rollbar from './rollbar';
import { AxiosError } from 'axios';

// Helper to extract error message from 'catch' errors
type ErrorWithMessage = {
  message: string;
};

function isErrorWithMessage(error: unknown): error is ErrorWithMessage {
  return (
    typeof error === 'object' &&
    error !== null &&
    'message' in error &&
    typeof (error as Record<string, unknown>).message === 'string'
  );
}

function toErrorWithMessage(maybeError: unknown): ErrorWithMessage {
  if (isErrorWithMessage(maybeError)) return maybeError;

  try {
    return new Error(JSON.stringify(maybeError));
  } catch {
    // fallback in case there's an error stringifying the maybeError
    // like with circular references for example.
    return new Error(String(maybeError));
  }
}

export function getErrorMessage(error: unknown) {
  return toErrorWithMessage(error).message;
}

export enum ErrorType {
  validation = 'validation',
  internal = 'internal',
  configuration = 'configuration',
  unhandled = 'unhandled',
}
export type WsErrorObject = {
  method: string;
  type: ErrorType;
  subType: string;
  code: string;
  message: string;
  identifierField?: string;
  identifiedValue?: string;
};

export const handleEmitCallbackError = (
  data: { error: WsErrorObject },
  rollbarErrorMessage: string,
) => {
  rollbar.critical(rollbarErrorMessage, { ...data?.error });
  notifications.show({
    title: 'Houve um erro interno e os responsáveis já foram avisados',
    message: '',
    color: 'red',
  });
};

export const shouldSkipLoggingForAuthError = (error: Error | AxiosError) => {
  if ('response' in error) {
    // Axios error
    const ignoreErrorList = [400, 401];
    if (error.response?.status && ignoreErrorList.includes(error.response.status)) {
      // Wrong email or password. Don't log it
      return true;
    }
  }
  return false;
};
