import { StoreInterface } from '@/stores/store';
import { StateCreator } from 'zustand';

export type ChatListSlice = {
  showSummaryData: boolean;
  setShowSummaryData: (value: boolean) => void;
};

export const createChatListSlice: StateCreator<
  StoreInterface,
  [],
  [],
  ChatListSlice /* Current slice type */
> = (set) => ({
  showSummaryData: false,
  setShowSummaryData: (value) => set({ showSummaryData: value }),
});
