import Rollbar, { Configuration, LogArgument } from 'rollbar';

import { env } from '@/env';

type LoggingTypes = 'log' | 'error' | 'info' | 'debug' | 'critical' | 'warn' | 'warning';

const clientToken = env.NEXT_PUBLIC_ROLLBAR_CLIENT_TOKEN;
const serverToken = env.NEXT_PUBLIC_ROLLBAR_SERVER_TOKEN;

export const hasToken = clientToken || serverToken;

const getRollbarEnvironment = (isClient: boolean) => {
  const project = isClient ? 'client' : 'server';

  return `${project}.${env.NEXT_PUBLIC_ROLLBAR_ENV || process.env.NODE_ENV}`;
};

export const getRollbarConfig = (isClient: boolean) => {
  const token = isClient ? clientToken : serverToken;
  return {
    enabled: !!token,
    environment: getRollbarEnvironment(isClient),
    accessToken: token,
    retryInterval: 1000,
    captureUncaught: true,
    captureUnhandledRejections: true,
    addErrorContext: true,
    payload: {
      client: {
        javascript: {
          // eslint-disable-next-line camelcase
          source_map_enabled: true,
          // eslint-disable-next-line camelcase
          code_version: env.NEXT_PUBLIC_VERCEL_GIT_COMMIT_SHA,
          // eslint-disable-next-line camelcase
          guess_uncaught_frames: true,
        },
      },
    },
  } as Configuration;
};

export const createRollbarInstance = (isClient: boolean) => new Rollbar(getRollbarConfig(isClient));

const rollbarInstance = createRollbarInstance(typeof window !== 'undefined');

export const handleRollbarLog =
  (type: LoggingTypes, instance?: Rollbar) =>
  (...args: LogArgument[]) => {
    const isClient = typeof window !== 'undefined';
    const token = isClient ? clientToken : serverToken;

    if (instance && process.env.NODE_ENV === 'development') {
      if (token) {
        console.log(`Sending to Rollbar from ${isClient ? 'client' : 'server'} side`, args);
      } else {
        console.log(
          `Error should be sent to Rollbar from ${isClient ? 'client' : 'server'} side`,
          args,
        );
      }
    }

    if (instance && token) {
      instance[type](...args);
    }
  };

// This rollbar variable should be used on server side ONLY. For client side use rollbar from @rollbar/react provider
const rollbar = {
  log: handleRollbarLog('log', rollbarInstance),
  error: handleRollbarLog('error', rollbarInstance),
  info: handleRollbarLog('info', rollbarInstance),
  debug: handleRollbarLog('debug', rollbarInstance),
  critical: handleRollbarLog('critical', rollbarInstance),
  warn: handleRollbarLog('warn', rollbarInstance),
  warning: handleRollbarLog('warning', rollbarInstance),
};

export default rollbar;
