'use client';

import { useStore } from '@/stores/store';
import axios from '@/utils/axios';
import { notifications } from '@mantine/notifications';
import useRollbar from '@/hooks/useRollbar';
import { ApiConstants, ErrorType, Keys } from './useApi';
import { AxiosError } from 'axios';

export type PutOptions<K> = {
  error: {
    message: string;
    showNotification?: boolean;
    shouldSkipLogging?: (error: Error | AxiosError) => boolean;
  };
  urlParams?: string;
  onSuccess?: (data: K) => void;
  onError?: (error?: ErrorType) => void;
};

function usePut<T>(key: Keys) {
  const updateData = useStore((store) => store.updateData);

  const rollbar = useRollbar();

  async function put<K>(
    data: T,
    options: PutOptions<K>,
    setLoading: (loading: boolean) => void,
    setError: (error?: ErrorType) => void,
    id?: string,
  ) {
    setLoading(true);
    setError(undefined);

    try {
      const res = await axios.put<K>(
        `${ApiConstants[key].url}${id ? `/${id}` : ''}${
          options.urlParams ? `${options.urlParams}` : ''
        }`,
        data,
      );
      const entity = ApiConstants[key].entity;
      if (entity) {
        updateData<(K & { id: string })[]>(entity, (state) => {
          return Array.isArray(state)
            ? state.map((s) => (s.id === (data as T & { id: string }).id ? { ...s, ...data } : s))
            : [{ ...(state as K & { id: string }), ...(data as K & { id: string }) }];
        });
      }

      setLoading(false);
      options.onSuccess?.(res.data);
    } catch (error) {
      const err = error as AxiosError<{ message: string }>;
      const errorMsg = err.response?.data?.message || err.message || 'Error';
      setLoading(false);
      if (!options.error.shouldSkipLogging?.(error as Error)) {
        rollbar.error(options.error.message, { error: JSON.stringify(err), message: errorMsg });
      }
      if (options.error.showNotification || options.error.showNotification === undefined) {
        notifications.show({
          title: 'Ocorreu um erro',
          message: options.error.message,
          color: 'red',
        });
      }

      const errorObj = { status: err?.response?.status, message: errorMsg };
      setError?.(errorObj);
      options.onError?.(errorObj);
    }
  }

  return { put };
}

export default usePut;
