'use client';

import { useStore } from '@/stores/store';
import axios from '@/utils/axios';
import { notifications } from '@mantine/notifications';
import useRollbar from '@/hooks/useRollbar';
import { useEffect } from 'react';
import { ApiConstants, ErrorType, Keys } from './useApi';
import { AxiosError } from 'axios';

export type GetOptions<K> = {
  error: {
    message: string;
    showNotification?: boolean;
    shouldSkipLogging?: (error: Error | AxiosError) => boolean;
  };
  params?: string;
  urlParams?: string;
  onSuccess?: (data: K) => void;
  onError?: (error?: ErrorType) => void;
  autoFetch: boolean;
};

function useGet<T>(
  key: Keys,
  options: GetOptions<T>,
  setLoading?: (loading: boolean) => void,
  setError?: (error?: ErrorType) => void,
) {
  const updateData = useStore((store) => store.updateData);

  const rollbar = useRollbar();

  async function get<K>(
    options: Omit<GetOptions<K>, 'autoFetch'>,
    setLoadingInside?: (loading: boolean) => void,
    setErrorInside?: (error?: ErrorType) => void,
  ) {
    setLoading?.(true);
    setLoadingInside?.(true);
    setError?.(undefined);
    setErrorInside?.(undefined);

    try {
      const { data } = await axios.get<K>(
        `${ApiConstants[key].url}${options.urlParams || ''}${
          options.params ? `?${options.params}` : ''
        }`,
      );
      const entity = ApiConstants[key].entity;
      if (entity) {
        updateData(entity, () => data || []);
      }
      setLoading?.(false);
      setLoadingInside?.(false);
      options.onSuccess?.(data);
    } catch (error) {
      const err = error as AxiosError<{ message: string }>;
      const errorMsg = err.response?.data?.message || err.message || 'Error';
      setLoading?.(false);
      setLoadingInside?.(false);

      if (!options.error.shouldSkipLogging?.(error as Error)) {
        rollbar.error(options.error.message, { error: JSON.stringify(err), message: errorMsg });
      }
      if (options.error.showNotification || options.error.showNotification === undefined) {
        notifications.show({
          title: 'Ocorreu um erro',
          message: options.error.message,
          color: 'red',
        });
      }
      const errorObj = { status: err?.response?.status, message: errorMsg };
      setError?.(errorObj);
      setErrorInside?.(errorObj);
      options.onError?.(errorObj);
    }
  }

  useEffect(() => {
    if (options.autoFetch) {
      get(options, setLoading, setError);
    }
  }, [options.autoFetch]);

  return { get };
}

export default useGet;
