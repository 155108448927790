import React, { PropsWithChildren, createContext, useContext, useEffect, useRef } from 'react';
import useApi from '@/hooks/useApi/useApi';
import { useFirebaseMessaging } from '@/hooks/useFirebaseMessaging';
import { useStore } from '@/stores/store';
import { User } from '@/typings/user';
import useRollbar from '@/hooks/useRollbar';
import { initMessaging } from '@/utils/firebase';

type NotificationBannerType = {
  requestPermission: () => Promise<void>;
  token: string | null;
  permissionStatus: NotificationPermission;
};

const NotificationBannerContext = createContext({} as NotificationBannerType);

const NotificationBannerProvider: React.FC<PropsWithChildren> = ({ children }) => {
  const verifiedToken = useRef(false);

  const updateData = useStore((store) => store.updateData);
  const user = useStore((store) => store.user);

  const [requestPermission, { permissionStatus, token }] = useFirebaseMessaging();
  const { post } = useApi('notificationToken');
  const rollbar = useRollbar();

  useEffect(() => {
    if (!user) return; // Should only post to the backend when the user is available

    const handleTokenPost = (token: string) => {
      post(
        { token },
        {
          error: {
            message: 'Error on sending to API the notification token',
            showNotification: false,
          },
          onSuccess: (updatedUser: User) => {
            updateData<User>('user', (user) => ({ ...user, ...updatedUser }));
          },
        },
      );
    };

    if (token && !verifiedToken.current) {
      verifiedToken.current = true;
      if (user?.notificationTokens && user?.notificationTokens?.length > 0) {
        // User already have tokens, checking if the token is not registered
        if (user.notificationTokens.indexOf(token) > -1) {
          // Token already on the list. Skipping
          return;
        }
      }
      handleTokenPost(token);
    } else if (
      permissionStatus === 'granted' &&
      (!user?.notificationTokens || user.notificationTokens.length === 0) &&
      !verifiedToken.current &&
      !token
    ) {
      // This code sets the notification token in the backend if the user has already allowed notifications before and does not have any notificationToken.
      // A situation that it might happen:
      // User logs in with an account and then accept notification permission.
      // After a while he logs out and then reconnect with a different account and the permission was already granted before
      verifiedToken.current = true;
      initMessaging(true)
        .then((newToken) => {
          if (newToken) {
            handleTokenPost(newToken);
          }
        })
        .catch((err) => {
          rollbar.error('Falha ao inicializar firebase cloud messaging - notificationBanner', err);
        });
    }
  }, [user, permissionStatus, token]);

  return (
    <NotificationBannerContext.Provider value={{ permissionStatus, token, requestPermission }}>
      {children}
    </NotificationBannerContext.Provider>
  );
};

export const useNotificationBanner = () => useContext(NotificationBannerContext);

export default NotificationBannerProvider;
