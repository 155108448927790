'use client';

import { useEffect, useState } from 'react';
import { getFirebaseMessaging, initMessaging } from '@/utils/firebase';
import useRollbar from '@/hooks/useRollbar';
import localforage from 'localforage';
import { useLocalStorage } from '@mantine/hooks';
import { NotificationData, notifications } from '@mantine/notifications';
import { useStore } from '@/stores/store';
import { isMobile } from '@/utils/platform';
import { MessagePayload } from 'firebase/messaging';
import { useRouter } from 'next/navigation';

export function useFirebaseMessaging() {
  const rollbar = useRollbar();
  const router = useRouter();
  const [token, setToken] = useState<string | null>(null);
  const [permissionStatus, setPermissionStatus] = useState<NotificationPermission>('default');

  useEffect(() => {
    if (typeof window !== 'undefined' && isSupported()) {
      setPermissionStatus(Notification.permission);
    }
  }, []);
  const user = useStore((store) => store.user);
  const [supported, setSupported] = useLocalStorage<boolean | null>({
    key: 'FCM_SUPPORTED',
    defaultValue: null,
  });

  async function requestPermission() {
    if (!supported) return;

    importFirebaseMessaging()
      .then(async (messaging) => {
        if (!messaging) return;
        if ('Notification' in window && (await messaging.isSupported())) {
          const permission = await Notification.requestPermission();
          setPermissionStatus(permission);
        }
      })
      .catch((error) => {
        rollbar.error('useFirebaseMessaging - Falha ao importar firebase messaging', {
          error: JSON.stringify(error),
          message: error?.message,
        });
      });
  }

  useEffect(() => {
    importFirebaseMessaging()
      .then((messaging) => {
        if (!messaging) {
          // Browser without the messaging
          setSupported(false);
          return;
        }
        messaging
          .isSupported()
          .then(setSupported)
          .catch((error) =>
            rollbar.error('Falha ao verificar suporte a notificações', {
              error: JSON.stringify(error),
              message: error?.message,
            }),
          );
      })
      .catch((error) =>
        rollbar.error('useFirebaseMessaging - Falha ao importar firebase messaging', {
          error: JSON.stringify(error),
          message: error?.message,
        }),
      );
  }, []);

  useEffect(() => {
    if (permissionStatus === 'granted' && supported) {
      initMessaging()
        .then((token) => {
          setToken(token || null);
        })
        .catch((error) => {
          rollbar.error('Falha ao inicializar firebase cloud messaging', {
            error: JSON.stringify(error),
            message: error?.message,
          });
        });
    } else {
      localforage.removeItem('FCM_TOKEN');
    }
  }, [permissionStatus, supported]);

  useEffect(() => {
    if (permissionStatus === 'granted' && supported && user) {
      importFirebaseMessaging()
        .then(async (messaging) => {
          if (!messaging) {
            if (!isMobile.iOS()) rollbar.warn('Messaging returning undefined', { messaging });
            return;
          }
          const firebaseMessaging = await getFirebaseMessaging();
          if (!firebaseMessaging) {
            return;
          }

          try {
            const unsubscribe = messaging.onMessage(firebaseMessaging, (payload) => {
              // Avoid showing notification if the tab is not focused
              if (!document.hasFocus()) {
                navigator.serviceWorker.ready.then((registration) => {
                  registration.active?.postMessage({
                    messageType: 'background-notification',
                    payload,
                  });
                });
                return;
              }
              const url = payload.data?.link || '';

              if (window.location.href.includes('/falar') || window.location.pathname === url) {
                // User testing website or already on the route, don't show notification
                return;
              }

              const id = notifications.show(
                generateNotificationConfig(payload, (destiny) => {
                  router.push(destiny);
                  notifications.hide(id);
                }),
              );
            });

            return () => {
              unsubscribe();
            };
          } catch (error) {
            rollbar.error('Error on onMessage listener', {
              error: JSON.stringify(error),
              firebaseMessaging: JSON.stringify(firebaseMessaging),
              message: (error as Error)?.message,
            });
          }
        })
        .catch((error) => {
          rollbar.error('Error on importing Firebase Messaging', {
            error: JSON.stringify(error),
            message: error?.message,
          });
        });
    }
  }, [permissionStatus, supported, user]);

  useEffect(() => {
    const handleFirebaseMessage = (event: MessageEvent) => {
      try {
        if (event.data.messageType === 'notification-clicked') {
          const payload: MessagePayload = event.data;
          if (payload.data?.link) {
            router.push(payload.data.link);
          }
        }
      } catch (error) {
        rollbar.error('Error on handling Firebase notification clicked event', {
          error: JSON.stringify(error),
        });
      }
    };

    navigator.serviceWorker.addEventListener('message', handleFirebaseMessage);
    return () => navigator.serviceWorker.removeEventListener('message', handleFirebaseMessage);
  }, [router]);

  return [requestPermission, { permissionStatus, token, supported }] as const;
}

export async function importFirebaseMessaging() {
  // Firebase messaging can only be imported on the client side
  if (typeof window === 'undefined') return;

  // Firebase messaging can only be imported on supported browsers
  if (!isSupported()) return;

  const messaging = await import('firebase/messaging');
  return messaging;
}

export function isSupported() {
  return 'Notification' in window && 'serviceWorker' in navigator && 'PushManager' in window;
}

// Also used on /src/views/Storybook/components/StorybookNotification.tsx
export const generateNotificationConfig = (
  payload: MessagePayload,
  onClick: (url: string) => void,
): NotificationData => {
  return {
    title: payload.notification?.title,
    message: payload.notification?.body,
    autoClose: false,
    onClick: (event) => {
      // Checking if user clicked on the close button
      if (!(event.target as HTMLElement).closest('.mantine-Notification-closeButton')) {
        onClick(payload.data?.link || '/');
      }
    },
    classNames: {
      root: 'text-white cursor-pointer bg-secondary-900 hover:bg-secondary-700 transition-colors',
      title: 'text-white font-bold',
      description: 'text-white',
      closeButton: 'text-white hover:bg-gray-50 hover:text-secondary-900 transition-colors',
    },
  };
};
