// IMPORTS
// -----------------------------------------
import React from 'react';
import { Button, MantineProvider, Text, Title } from '@mantine/core';
import useRollbar from '@/hooks/useRollbar';

type Props = {
  statusCode?: number;
  error?: Error | null;
};

const Error: React.FC<Props> = (props) => {
  const logging = useRollbar();
  React.useEffect(() => {
    if (props.statusCode !== 404)
      logging.error(`[${props.statusCode}] User on the error page`, {
        ...props,
      });
  }, []);

  const message = React.useMemo(() => {
    return props.statusCode === 404
      ? 'Ops, não encontramos o que você está procurando'
      : 'Desculpe, algo não saiu como esperado. Nossa equipe será avisada e cuidará disso em breve.';
  }, [props.statusCode, props.error]);

  return (
    <MantineProvider>
      <div className="flex flex-col justify-center items-center h-screen w-screen text-center">
        {props.statusCode === 404 && (
          <>
            <Title className="m-0" mb="sm">
              {props.statusCode || 500}
            </Title>
          </>
        )}
        <Text className="m-0 mb-2 text-balance" mb="md">
          {message}
        </Text>
        {(props.statusCode === 500 || props.statusCode === 404 || !props.statusCode) && (
          <Button className="max-w-[300px]" onClick={() => (window.location.href = '/')}>
            Voltar para o início
          </Button>
        )}
      </div>
    </MantineProvider>
  );
};

export default Error;
