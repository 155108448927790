import { StoreInterface } from '@/stores/store';
import { StateCreator } from 'zustand';

export type UnsavedChangesSlice = {
  hasUnsavedChanges: boolean;
  unsavedChanges: Record<string, boolean>;
  setUnsavedChanges: (key: string, unsavedChanges: boolean) => void;
  resetUnsavedChanges: () => void;
};

export const createUnsavedChangesSlice: StateCreator<
  StoreInterface,
  [],
  [],
  UnsavedChangesSlice /* Current slice type */
> = (set, get) => ({
  hasUnsavedChanges: false,
  unsavedChanges: {},
  setUnsavedChanges(key, unsavedChanges) {
    const prevChanges = get().unsavedChanges;
    const newChanges = { ...prevChanges, [key]: unsavedChanges };
    const hasUnsavedChanges = Object.values(newChanges).some(Boolean);
    set({ unsavedChanges: newChanges, hasUnsavedChanges });
  },
  resetUnsavedChanges() {
    set({ unsavedChanges: {}, hasUnsavedChanges: false });
  },
});
