// helps you detect mobile browsers (to show a relevant message as the process of installing your PWA changes from browser to browser)

const isEnvironmentReady = typeof window !== 'undefined' && typeof navigator !== 'undefined';

export const isMobile = {
  Android: function () {
    return isEnvironmentReady && !!navigator.userAgent.match(/Android/i);
  },
  BlackBerry: function () {
    return isEnvironmentReady && !!navigator.userAgent.match(/BlackBerry/i);
  },
  iOS: function () {
    return isEnvironmentReady && !!navigator.userAgent.match(/iPhone|iPad|iPod/i);
  },
  Opera: function () {
    return isEnvironmentReady && !!navigator.userAgent.match(/Opera Mini/i);
  },
  Samsung: function () {
    return (
      isEnvironmentReady &&
      !!navigator.userAgent.match(
        /SAMSUNG|Samsung|SGH-[I|N|T]|GT-[I|N]|SM-[A|N|P|T|Z]|SHV-E|SCH-[I|J|R|S]|SPH-L/i,
      )
    );
  },
  Windows: function () {
    return (
      (isEnvironmentReady && !!navigator.userAgent.match(/IEMobile/i)) ||
      navigator.userAgent.match(/WPDesktop/i)
    );
  },
  any: function () {
    return (
      isMobile.Android() ||
      isMobile.BlackBerry() ||
      isMobile.iOS() ||
      isMobile.Opera() ||
      isMobile.Windows()
    );
  },
};

export const isBrowser = {
  Chrome: function () {
    return (
      isEnvironmentReady &&
      !!navigator.userAgent.match(/Chrome|CriOS/i) &&
      !navigator.userAgent.match(/OPR|Edg/i)
    );
  },
  Firefox: function () {
    return isEnvironmentReady && !!navigator.userAgent.match(/Firefox|FxiOS/i);
  },
  Safari: function () {
    return (
      isEnvironmentReady &&
      !!navigator.userAgent.match(/Safari/i) &&
      !navigator.userAgent.match(/Chrome|CriOS|OPR|Edg/i)
    );
  },
  Opera: function () {
    return isEnvironmentReady && !!navigator.userAgent.match(/Opera|OPR/i);
  },
  Edge: function () {
    return isEnvironmentReady && !!navigator.userAgent.match(/Edg/i);
  },
  IE: function () {
    return isEnvironmentReady && !!navigator.userAgent.match(/MSIE|Trident/i);
  },
  any: function () {
    return (
      isEnvironmentReady &&
      (isBrowser.Chrome() ||
        isBrowser.Firefox() ||
        isBrowser.Safari() ||
        isBrowser.Opera() ||
        isBrowser.Edge() ||
        isBrowser.IE())
    );
  },
};

export const getBrowserName = () => {
  if (isBrowser.Chrome()) {
    // Separating chrome to avoid confusion
    if (isMobile.Android()) return 'Google Chrome Android';
    else if (isMobile.iOS()) return 'Google Chrome iOS';
    else return 'Google Chrome';
  }
  if (isBrowser.Safari()) return 'Safari';
  if (isBrowser.Firefox()) return 'Firefox';
  if (isBrowser.Opera()) return 'Opera';
  if (isBrowser.Edge()) return 'Microsoft Edge';
  if (isBrowser.IE()) return 'Internet Explorer';
  return 'seu navegador';
};

export const isPWA = (): boolean => {
  if (typeof window === 'undefined') return false;
  if (window.matchMedia('(display-mode: standalone)').matches) {
    return true;
  }

  if ('standalone' in window.navigator && window.navigator['standalone']) {
    return true;
  }

  return false;
};

// use this to check if the user is already using your PWA - no need to prompt if in standalone
export const isStandalone = (): boolean => {
  const isStandalone = window.matchMedia('(display-mode: standalone)').matches;
  if (document.referrer.startsWith('android-app://')) {
    return true; // Trusted web app
  } else if ('standalone' in navigator || isStandalone) {
    return true;
  }
  return false;
};
