import { Organization } from '@/typings/backend';
import { StoreInterface } from '@/stores/store';
import { StateCreator } from 'zustand';

export type AppDataSlice = {
  organization: Organization | null;
  emitNewMessageSound: boolean;
  setEmitNewMessageSound: (value: boolean) => void;
  setOrganization: (org: Organization) => void;
};

export const createAppDataSlice: StateCreator<
  StoreInterface,
  [],
  [],
  AppDataSlice /* Current slice type */
> = (set) => ({
  organization: null,
  emitNewMessageSound: false,
  setEmitNewMessageSound: (value) => {
    set({ emitNewMessageSound: value });
  },
  setOrganization: (organization) => {
    set({ organization });
  },
});
