import { StoreInterface } from '@/stores/store';
import { StateCreator } from 'zustand';

export type ProfilePictureSlice = {
  profilePictureQueue: string[];
  addToProfilePictureQueue: (value: string) => void;
  removeProfilePictureQueue: (value: string) => void;
};

export const createProfilePictureSlice: StateCreator<
  StoreInterface,
  [],
  [],
  ProfilePictureSlice /* Current slice type */
> = (set, get) => ({
  profilePictureQueue: [],
  addToProfilePictureQueue: (value) => {
    const currentQueue = get().profilePictureQueue;
    if (currentQueue.includes(value)) return;
    set({ profilePictureQueue: [...currentQueue, value] });
  },
  removeProfilePictureQueue: (value) =>
    set((state) => ({
      profilePictureQueue: state.profilePictureQueue.filter((item) => item !== value),
    })),
});
