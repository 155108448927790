import { rem } from '@mantine/core';
import ShadeGenerator, { type Shade } from 'shade-generator';

export const breakpoints = {
  sm: '40em',
  md: '48em',
  lg: '64em',
  xl: '80em',
  '2xl': '96em',
};

// All colors created here need to be added on tailwind.config.ts
export const colors = {
  primary: createColorArray('#118df0'), // Primary color (used for main elements like buttons)
  secondary: createColorArray('#002169'), // Secondary color (used for text or background contrasts)
  accent: createColorArray('#41a4f3'), // Accent color (used for highlights or subtle details)
  link: createColorArray('#0c71c2'), // Link color (used for clickable elements or hyperlinks)
  darkAccent: createColorArray('#00319c'), // Dark accent color (used for dark contrasts or emphasis)
  green: createColorArray('#15C15D'), // Success color (used for success messages or states)
  yellow: createColorArray('#F59E0B'), // Warning color (used for warnings or alerts)
  blue: createColorArray('#3B82F6'), // Info color (used for informational messages)
  red: createColorArray('#EF4444'), // Error color (used for error messages or states)
  light: createColorArray('#F9FAFB'), // Light background color (used for backgrounds or light sections)
  dark: createColorArray('#111827'), // Dark text color (used for main text or dark backgrounds)
};

export const spacings = {
  xs: rem(4),
  sm: rem(8),
  md: rem(16),
  lg: rem(24),
  xl: rem(32),
};

export const fontSize = {
  xs: rem(12),
  sm: rem(14),
  md: rem(16),
  lg: rem(18),
  xl: rem(20),
};

// Function to create an array of 10 different shades of a color
function createColorArray(color: string) {
  const config: Record<Shade, number> = {
    '10': 0,
    '20': 0,
    '30': 0,
    '40': 0.9,
    '50': 0.75,
    '60': 0.6,
    '70': 0.45,
    '80': 0.3,
    '90': 0.15,
    '100': 0,
    '200': 0.9,
    '300': 0.8,
    '400': 0.7,
    '500': 0,
    '600': 0,
    '700': 0,
    '800': 0,
    '900': 0,
    '1000': 0,
  };

  const colorMap = ShadeGenerator.hue(color).config(config).shadesMap('hex');
  const colors = [
    colorMap['40'],
    colorMap['50'],
    colorMap['60'],
    colorMap['70'],
    colorMap['80'],
    colorMap['90'],
    colorMap['100'],
    colorMap['200'],
    colorMap['300'],
    colorMap['400'],
  ] as const;

  return colors;
}
