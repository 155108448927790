import { handleRollbarLog } from '@/utils/rollbar';
import { useRollbar as useRollbarLib } from '@rollbar/react';

export default function useRollbar() {
  const rollbar = useRollbarLib();

  return {
    log: handleRollbarLog('log', rollbar),
    error: handleRollbarLog('error', rollbar),
    info: handleRollbarLog('info', rollbar),
    debug: handleRollbarLog('debug', rollbar),
    critical: handleRollbarLog('critical', rollbar),
    warn: handleRollbarLog('warn', rollbar),
    warning: handleRollbarLog('warning', rollbar),
  };
}
