'use client';

import { Provider, StoreInterface, StoreType, initializeStore } from '@/stores/store';
import { type PropsWithChildren, useRef } from 'react';

export interface PreloadedStoreInterface {
  initialData?: Partial<Omit<StoreInterface, 'updateData'>>;
}

export default function StoreProvider({
  children,
  ...props
}: PropsWithChildren<PreloadedStoreInterface>) {
  const storeRef = useRef<StoreType>();

  if (!storeRef.current) {
    storeRef.current = initializeStore(props);
  }

  return <Provider value={storeRef.current}>{children}</Provider>;
}
